import * as React from "react"
import { Container,Box, Grid, Typography, Hidden } from "@material-ui/core"
import Seo from "../../components/seo"
import Menu from "../../components/festival/menu"
import PraktischTitle from "../../components/festival/praktischTitle"
import PropTypes from 'prop-types';

const ticketInfo = <>
Toegang tot het TOMATO FESTIVAL is enkel mogelijk met een geldig toegangsticket op die dag. Om de social distancing maatregelen te garanderen worden de tickets minstens per 2 aangekocht. En maximum per 8.
Tickets betaald maar niets ontvangen? Controleer best even je SPAM box.
Tickets verloren? Ontvang ze opnieuw via de ticket site of mail naar: <a style={{textDecoration: "none", color:"#ffffff"}}href="mailto:tickets@tomatostories.be">tickets@tomatostories.be</a>
<br/><br/>
Met je ticket gekocht in voorverkoop geraak je altijd binnen.
We verkopen niet meer tickets dan de capaciteit van ons terrein.
Per dag zijn er 200 tickets beschikbaar.
<br/><br/>
Opgelet! Een avond kan uitverkocht zijn in voorverkoop. Indien dit voorvalt wordt dit aangekondigd. Als een dag is uitverkocht kan je je hier inschrijven op een wachtlijst.
Het plein wordt gradueel opgevuld. Hoe vroeger je bent, hoe meer we jou een tafel vooraan kunnen toewijzen.
</>

const vervoerEnParking = <>
Met het openbaar vervoer:<br/>
Neem de trein naar Station Kortrijk (reistijden via belgiantrain.be)
Aan station Kortrijk neem je de bus Lijn 71 tot aan de halte ‘Kortrijk Katoenspinnerij’ vlak voor de festival locatie. (reistijden via delijn.be)
Ook te voet is het te doen, 30min wandelen door centrum Kortrijk en langs Kanaal Bossuit-Kortrijk.
Aan het station van Kortrijk zijn ook Blue Bikes te vinden en Cambio-standplaatsen.
<br/><br/>
Met de fiets:<br/>
Wie niet ver woont raden wij aan met de fiets te komen.
Aan de ingang van de site (Spinnerijstraat 105, Kortrijk) is een ruime fietsenparking.
<br/><br/>
Met de auto:<br/>
Wij raden iedereen aan de auto thuis te laten en met de fiets te komen om ecologische maar ook om praktische redenen. De parkeermogelijkheden vlakbij de site zijn beperkt.
Voor wie geen andere keuze heeft kan je parkeren op de voorziene parking alsook langs de Spinnerijstraat en de Venetiëlaan te Harelbeke.

</>


const toegankelijkheid = <>
De Bolwerk site is rolstoel toegankelijk. 
Echter parkeermogelijkheden dichtbij zijn eerder beperkt dus mocht dit een probleem voor u kunnen worden stuur gerust een mailtje naar: <a style={{textDecoration: "none", color:"#ffffff"}}href="mailto:info@tomatostories.be">info@tomatostories.be</a>
<br/>
Assistentie- en blindengeleidehonden zijn toegelaten.

</>

const veiligheid = <>
Het dragen van een mondmasker is nog steeds verplicht enkel als u neerzit mag u uw mondmasker achterwege laten. 
<br/>
Bestellen kan aan de bar met mondmasker. 
<br/>
Hou altijd voldoende afstand van anderen. 
<br/>
Blijf thuis als u zich ziek voelt, recent naar een rode zone reisde of positief bent getest. 
<br/><br/>
Er worden geen drugs, eigen drank, promotiemateriaal, wapens nog scherpe voorwerpen toegelaten op het festival.
Om veiligheidsredenen is er een maximaal publieksaantal.
<br/>
Wij zijn dierenvrienden, maar huisdieren worden niet toegelaten op het terrein.

</>

const etenEnDrinken = <>
Op TOMATO FESTIVAL is de Bolwerk Bar open.
Je geniet er van een ambachtelijk biertje of een heerlijk sapje.
Ze werken met lokale, fairtrade of biologische producten.
<br/><br/>
Om een hapje te eten zal de Foodtruck van Manuel Kartel aanwezig zijn.
<br/>
Betalen kan cash of met bancontact.
</>

const watWaarWanneer = <>
TOMATO FESTIVAL is een 3-daags muziekfestival.
<br/>
Dit jaar vindt dit plaats van 18 tot 20 augustus op de Bolwerk site te Kortrijk.
Spinnerijstraat 105, 8500 Kortrijk
<br/><br/>
De precieze aanvangsuren verschillen per dag.
Het terrein dient volledig ontruimd te zijn tegen 00u00.
</>

const PraktischItem = ({openItem, id, text}) =>  (
   openItem === id
    &&  (
     <Grid item xs={12}>
      <Typography variant="body2" color="primary">
        {text}
      </Typography>
    </Grid>
  )
) 

const PraktischPage = () => {
  const [openItem, setOpenItem] = React.useState(null);

  const changeOpenItem = (item) => {
    setOpenItem(item);
  }

  return (

    <Container maxWidth="lg">
    <Seo title="Tomatostories - Praktisch" />
      <Box>
      <Grid container spacing={2}>
          <Grid item xs={12}>
          <Menu/>
          </Grid>
          <Grid item xs={12} md={4} onClick={() => changeOpenItem("TicketInfo")} >
            <PraktischTitle 
              title="TICKETINFO"
              open={openItem === "TicketInfo"} />
          </Grid>
          <Hidden mdUp>
            <PraktischItem openItem={openItem} id="TicketInfo" text={ticketInfo}/>
          </Hidden>
          <Grid item xs={12} md={4}  onClick={() => changeOpenItem("VervoerEnParking")} >
            <PraktischTitle 
               title="VERVOER EN PARKING"
               open={openItem === "VervoerEnParking"}/>
          </Grid>
          <Hidden mdUp>
            <PraktischItem openItem={openItem} id="VervoerEnParking" text={vervoerEnParking}/>
          </Hidden>
          <Grid item xs={12} md={4} onClick={() => changeOpenItem("Toegankelijkheid")}>
            <PraktischTitle 
              title="TOEGANKELIJKHEID"
              open={openItem === "Toegankelijkheid"} />
          </Grid>
          <Hidden smDown>
            <PraktischItem openItem={openItem} id="TicketInfo" text={ticketInfo}/>
            <PraktischItem openItem={openItem} id="VervoerEnParking" text={vervoerEnParking}/>
          </Hidden>
          <PraktischItem openItem={openItem} id="Toegankelijkheid" text={toegankelijkheid}/>

           <Grid item xs={12} md={4} onClick={() => changeOpenItem("Veiligheid")}>
            <PraktischTitle 
              title="VEILIGHEID / CORONA"
              open={openItem === "Veiligheid"} />
          </Grid>
          <Hidden mdUp>
            <PraktischItem openItem={openItem} id="Veiligheid" text={veiligheid}/>
          </Hidden>
          <Grid item xs={12} md={4} onClick={() => changeOpenItem("EtenEnDrinken")}>
            <PraktischTitle 
              title="ETEN & DRINKEN"
              open={openItem === "EtenEnDrinken"} />
          </Grid>
          <Hidden mdUp>
            <PraktischItem openItem={openItem} id="EtenEnDrinken" text={etenEnDrinken}/>
          </Hidden>
          <Grid item xs={12} md={4} onClick={() => changeOpenItem("WatWaarWanneer")}>
            <PraktischTitle 
              title="WAT/WAAR/WANNEER"
              open={openItem === "WatWaarWanneer"} />
          </Grid>
          <Hidden smDown>
            <PraktischItem openItem={openItem} id="Veiligheid" text={veiligheid}/>
            <PraktischItem openItem={openItem} id="EtenEnDrinken" text={etenEnDrinken}/>
          </Hidden>
          <PraktischItem openItem={openItem} id="WatWaarWanneer" text={watWaarWanneer}/>
      </Grid>
      </Box>
    </Container>

)}

PraktischItem.propTypes = {
  openItem: PropTypes.string,
  id: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired
}

export default PraktischPage
