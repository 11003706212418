import * as React from "react";
import PropTypes from 'prop-types';
import {Box, Hidden, makeStyles, Typography} from '@material-ui/core';
import { StaticImage } from "gatsby-plugin-image"
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 800,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    box: {
        backgroundColor: theme.palette.secondary.main,
        padding: theme.spacing(4),
        height: "156px",
        clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0 70%)",
        
    },
    imageBox: {
        height: "100%",
    },
    positionBox:{
        position: "relative",
        textalign: "center",
        cursor: "pointer"
    }
  }));


const PraktischTitle = ({title, open}) => {
    const classes = useStyles();
    /* if(open){
        return <Box className={clsx(classes.positionBox, classes.imageBox)}>
                    <Hidden smDown mdUp>
                        <StaticImage 
                                src="../../images/praktisch-open-groot.png" 
                                alt={title}
                                placeholder="blurred"
                                />   
                    </Hidden>
                    <Hidden smUp mdDown>
                        <StaticImage 
                                src="../../images/praktisch-open.png" 
                                alt={title}
                                placeholder="blurred"
                                />    
                    </Hidden>
                    <Typography variant="h6" component="h2" className={classes.title}>{title}</Typography>
                </Box>
        
    } */
    if(open)
    return (
        <Box className={clsx(classes.positionBox, classes.box)}>
            <Typography color="primary" variant="h6" component="h2" className={classes.title}>{title}</Typography>
        </Box>
    )
    return (
        <Box className={clsx(classes.positionBox, classes.box)}>
            <Typography variant="h6" component="h2" className={classes.title}>{title}</Typography>
        </Box>
    )
}

PraktischTitle.propTypes = {
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired
}

export default PraktischTitle